import React, { useRef } from "react";
import Formular from "../components/Formular";
import Seo from "../components/seo";



export default function IndexPage({

}) {

    const ref = useRef(null);
    const inputCls = "inline-block box-border text-3xl bg-transparent border-transparent border-b-white border-2 focus:border-transparent focus:border-b-white focus:outline-transparent focus:ring-transparent"

    return (

        <div>
            <Seo title='Kontakt - Laila schreibt' />

            <div
                className='py-24 bg-themeTuerkis relative max-w-screen snap-start'>
                <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-5xl grid grid-cols- gap-24">
                    <div className="relative flex w-full">
                        <div className="mx-auto">
                            <h3 className="py-4 text-xl mb-2 font-heading leading-relaxed">Sali, ich bin Laila.</h3>
                            <h2 className="py-4 text-5xl  mb-8 font-heading leading-relaxed">Wer bist du? Wie kann ich dir behilflich sein?</h2>

                            <div className="flex flex-col gap-4 text-xl hyphens-auto text-justify leading-relaxed justify-center">
                                <p className="prose font-light">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Facilisis gravida neque convallis a cras semper auctor neque. Dolor sit amet consectetur adipiscing elit duis tristique sollicitudin. Pulvinar pellentesque habitant morbi tristique senectus. Ac orci phasellus egestas tellus rutrum tellus pellentesque eu tincidunt. Arcu cursus euismod quis viverra nibh cras pulvinar mattis nunc.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="">
                        <div
                            className='relative block'>
                            <form className="md:text-3xl md:leading-loose">
                                Meine Name ist
                                <input
                                    className={`${inputCls} mx-4`}
                                    type="text"
                                />
                                und interessiere mich für
                                <input
                                    className={`${inputCls} w-full`}
                                    type="text"
                                />
                                Ich bin erreichbar unter
                                <input
                                    className={`${inputCls} mx-4`}
                                    type="text"
                                />
                                <button
                                    className="block border-4 border-black px-4 py-2 my-12"
                                >Ab die Post</button>
                            </form>

                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}